import React, { useContext } from 'react';
import classNames from 'classnames';

import AppContext from '../../../state/context/AppContext';
import TopicEditor from '../editor/TopicEditor';
import TopicList from '../list/TopicsList';

import './topics-column.css';


export default () => {
  const { state: { settings, topics} } = useContext(AppContext);
  const { showTypesColumn } = settings;
  const { references, edit, create } = topics;

  return (
    <div className={classNames('topics-column', { 'show-types-column': showTypesColumn })}>
      { edit && <TopicEditor topic={references[edit]} /> }
      { create && <TopicEditor topic={null} /> }
      { !edit && !create && <TopicList /> }
    </div>
  );
};
