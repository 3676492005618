import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

var config = {
  apiKey: "AIzaSyAIWoVMKDg1nZZC_ehajGSOTiOZqQfWCnE",
  authDomain: "knowledge-database-74d4b.firebaseapp.com",
  databaseURL: "https://knowledge-database-74d4b.firebaseio.com",
  projectId: "knowledge-database-74d4b",
  storageBucket: "knowledge-database-74d4b.appspot.com",
  messagingSenderId: "682114700331",
};
firebase.initializeApp(config);

export default firebase;

export const db = firebase.firestore();
export const auth = firebase.auth();

db.enablePersistence()
  .catch(function(err) {
      if (err.code == 'failed-precondition') {
        console.error('Cannot use offline mode: multiple tabs open')
      } else if (err.code == 'unimplemented') {
        console.error('Cannot use offline mode: unsupported')
      }
  });

window.auth = auth;
window.db = db;
