import React, { useContext } from 'react';
import classNames from 'classnames';

import AppContext from '../../../state/context/AppContext';
import TypeEditor from '../editor/TypeEditor';
import TypeList from '../list/TypesList';

import './types-column.css';


export default () => {
  const { state: { settings, types } } = useContext(AppContext);
  const { showTypesColumn } = settings;
  const { references, edit, create } = types;

  return (
    <div className={classNames('types-column', { 'show-types-column': showTypesColumn })}>
      { edit && <TypeEditor type={references[edit]} /> }
      { create && <TypeEditor type={null} /> }
      { !edit && !create && <TypeList /> }
    </div>
  );
};
