import React, { useContext } from 'react';

import AppContext from '../../../state/context/AppContext';
import Button from '../../basic/button/Button';
import ListColumnHeader from '../../basic/column-header/list/ListColumnHeader';
import Type from '../item/Type';

import './types-list.css';


export default () => {
  const { state: { types }, dispatch } = useContext(AppContext);
  const { list, references, sortField, sortDirection } = types;

  const handleSort = field => (
    dispatch({ type: 'types/SORT', sortField: field, sortDirection: field === sortField && sortDirection > 0 ? -1 : 1 })
  );

  const sortIcon = (field, icon) => (
    sortField === field && sortDirection < 0 ? `${icon}-desc` : `${icon}-asc`
  );

  const handleCreateClick = () => (
    dispatch({ type: 'types/CREATE', create: true })
  );

  const handleFilterChange = value => (
    dispatch({ type: 'types/FILTER', field: 'name', fuzzy: true, filter: value })
  );


  return (
    <>
      <ListColumnHeader onCreateClick={handleCreateClick} onFilterChange={handleFilterChange} showTypesButton>
        <Button onClick={handleSort.bind(null, 'name')} icon={sortIcon('name', 'sort-alpha')} active={sortField === 'name'} />
        {//<Button onClick={handleSort.bind(null, 'topicCount')} icon={sortIcon('topicCount', 'sort-amount')} active={sortField === 'topicCount'} />
        }
      </ListColumnHeader>
      <div className="types-list">
        {list.map(id => (
          <Type key={id} type={references[id]} />
        ))}
      </div>
    </>
  );
};
