import React, { useContext } from 'react';

import AppContext from '../../../../state/context/AppContext';
import ProjectTab from './ProjectTab';

import './projects.css';


export default () => {
  const { state: { projects: { list, references } } } = useContext(AppContext);

  return (
    <div className="project-tab__container">
      {list.map(project => <ProjectTab key={project} project={references[project]} />)}
    </div>
  );
}
