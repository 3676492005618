import React from 'react';

import './topic-badge.css';


export default ({ children }) => (
  <div className="topic-badge__group">
    {children}
  </div>
);
