import React from 'react';
import { NavLink } from 'react-router-dom';

import './projects.css';


export default ({ project }) => (
  <NavLink className="project-tab" activeClassName="project-tab--active" to={`/${project.id}`}>
    {project.get('name')}
  </NavLink>
);
