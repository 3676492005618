
export const getDocument = (resourceState, id) => (
  resourceState && resourceState.references && id
    ? resourceState.references[id]
    : null
);

export const getSelected = resourceState => (
  getDocument(resourceState, resourceState ? resourceState.selected : null)
);

export const getEdit = resourceState => (
  getDocument(resourceState, resourceState ? resourceState.edit : null)
);

export const getRemove = resourceState => (
  getDocument(resourceState, resourceState ? resourceState.remove : null)
);

export const getList = resourceState => (
  resourceState && resourceState.references && resourceState.list
    ? resourceState.list.map(doc => resourceState.references[doc])
    : []
);

export const getAll = resourceState => (
  Object.keys(resourceState.references).map(id => resourceState.references[id])
);

export const getFiltered = (resourceState, filter) => (
  Object.keys(resourceState.references).map(id => resourceState.references[id]).filter(filter)
);
