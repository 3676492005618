import React from 'react';
import classNames from 'classnames';

import Button from '../../button/Button';

import './editor.css';


export default ({ onCancel, left, showSaveButton = true }) => (
  <div className={classNames('editor-header', { 'editor-header--left': left })}>
    <div className="editor-header__inner">
      <Button icon="close" onClick={onCancel} />
      {showSaveButton && <Button primary icon="save" type="submit" />}
    </div>
  </div>
);
