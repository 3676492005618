import React from 'react';

import './loading.css';


export default () => (
  <div className="loading">
    <span className="loading__indicator">
      <i className="fa fa-circle-o-notch fa-spin" />
    </span>
  </div>
);
