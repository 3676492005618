import React from 'react';
import classNames from 'classnames';

import './input.css';


export default ({ name, label, placeholder, value, onChange, icon, ...rest }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <div className={classNames('input input--text', { 'input--icon': !!icon })}>
      <label htmlFor={name}>{label}</label>
      {icon && <i className={`fa fa-${icon} input__icon`} />}
      <input name={name} type="text" value={value} onChange={handleChange} placeholder={placeholder} {...rest} />
    </div>
  );
};
