import React from 'react';
import classNames from 'classnames';

import './input.css';


export default ({ caption, label, value, onChange }) => {
  const handleClick = (e) => {
    e.preventDefault();

    if (onChange) {
      onChange(!value);
    }
  }

  return (
    <div className="input input--checkbox">
      <label>{caption}</label>
      <div className="input--checkbox__container">
        <a href="#"
          className={classNames('input--checkbox__option', { 'input--checkbox__option--selected': value })}
          onClick={handleClick}>
          <i className={classNames('fa', { 'fa-circle': !value, 'fa-check-circle': value })} /> {label}
        </a>
      </div>
    </div>
  );
};
