import React, { useState } from 'react';

import { auth } from '../../../config/firebase';
import Button from '../../basic/button/Button';

import './login.css';


export default () => {
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');


  const handleSubmit = e => {
    e.preventDefault();
    auth.signInWithEmailAndPassword(email, password)
      .catch(e => console.log(e));
  };

  const handleEmailChange = e => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handlePasswordChange = e => {
    e.preventDefault();
    setPassword(e.target.value);
  };


  return (
    <div className="login">
      <form onSubmit={handleSubmit} className="login__form">
        <input placeholder="E-Mail" type="email" name="email" value={email} onChange={handleEmailChange} />
        <input placeholder="Passwort" type="password" name="password" value={password} onChange={handlePasswordChange} />
        <Button primary type="submit" icon="arrow-right" />
      </form>
    </div>
  );
};
