import React from 'react';

import './input.css';


export default ({ name, label, placeholder, value, onChange, ...rest }) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  }

  return (
    <div className="input input--text-area">
      <label htmlFor={name}>{label}</label>
      <textarea name={name} value={value} onChange={handleChange} {...rest} />
    </div>
  );
};
