import { useContext, useEffect } from 'react';

import AppContext from '../../../state/context/AppContext';
import { getSelected } from '../../../state/utils/stateUtils';


export default ({ children }) => {
  const { state: { topics, projects }, dispatch } = useContext(AppContext);

  const currentTopic = getSelected(topics);

  useEffect(() => {
    if (currentTopic) {
      return currentTopic.ref.collection('relations').onSnapshot(
        snapshot => dispatch({ type: 'relations/SET', docs: snapshot.docs })
      );
    }
  }, [currentTopic]);

  return children;
};
