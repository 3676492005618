import React, { useContext, useEffect, useState } from 'react';

import AppContext from '../../../state/context/AppContext';
import TopicRelation from '../topic-relation/TopicRelation';
import EmptyTopicRelation from '../topic-relation/EmptyTopicRelation';
import { getAll, getDocument } from '../../../state/utils/stateUtils';

import './topic-relation-editor.css';


export default ({ relatedTopic, onRelatedTopicChange }) => {
  const { state: { types, topics } } = useContext(AppContext);
  const [ topic, setTopic ] = useState(null);
  const [ topicType, setTopicType ] = useState(null);
  const [ availableTopics, setAvailableTopics ] = useState([]);
  const [ filter, setFilter ] = useState('');

  useEffect(() => {
    if (relatedTopic) {
      setTopic(relatedTopic || null);
      setTopicType(relatedTopic ? (getDocument(types, relatedTopic.get('type')) || null) : null);
    }
  }, [relatedTopic, types, topics])

  useEffect(() => {
    if (filter && filter.length > 1) {
      const regexp = new RegExp(`(${filter.replace(' ', '.*')})`, 'i');
      setAvailableTopics(
        getAll(topics)
          .filter(t => t != topic)
          .filter(t => t.get('name').match(regexp))
          .map(t => {
            const type = getDocument(types, t.get('type'));
            return {
              id: t.id,
              icon: type.get('icon'),
              name: t.get('name'),
            };
          })
      );
    } else {
      setAvailableTopics([]);
    }
  }, [filter, topic]);

  useEffect(() => {
    onRelatedTopicChange(topic);
  }, [topic]);


  const handleClick = (operation, id) => {
    switch (operation) {
      case 'SELECT':
        const t = getDocument(topics, id);
        setTopic(t || null);
        setTopicType(t ? (getDocument(types, t.get('type')) || null) : null);
        break;
      case 'REMOVE':
        setTopic(null);
        setTopicType(null);
        break;
    }
  }

  const handleFilterChange = e => {
    setFilter(e.target.value);
  }

  return (
    <div className="topic-relation-editor">
      <label>Ausgewähltes Thema</label>
      <div className="topic-relation-editor__selected-topic">
        {topic &&
            <TopicRelation icon={topicType ? topicType.get('icon') : null} name={topic.get('name')} actionIcon="close" selected onClick={handleClick.bind(null, 'REMOVE', topic.id)} />
        }
        {!topic &&
          <EmptyTopicRelation text="Kein Thema ausgewählt." />
        }
      </div>
      <label>Alle Themen</label>
      <div className="topic-relation-editor__not-selected-topics">
        <input className="topic-relation-editor__filter" type="text" placeholder="Filter..." value={filter} onChange={handleFilterChange} />
        {availableTopics.map(({ id, icon, name }) => (
          <TopicRelation key={id} icon={icon} name={name} actionIcon="check" onClick={handleClick.bind(null, 'SELECT', id)} />
        ))}
        {(!availableTopics || availableTopics.length < 1) &&
          <EmptyTopicRelation text={!filter || filter.length < 2 ? 'Filter nutzen um Auswahl einzuschränken' : 'Keine passenden Themen gefunden.'} />
        }
      </div>
    </div>
  );
};
