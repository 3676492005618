
export default (state, action) => {
  switch (action.type) {
    case 'settings/TOGGLE_TYPES_COLUMN':
      return {
        ...state,
        showTypesColumn: !state.showTypesColumn,
      };

    default:
      return { ...state };
  }
}
