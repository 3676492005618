import { createReferences, createList, resetIfUnknown } from './reducerUtils';

export default (resourceName) => {
  return (state, action) => {
    switch (action.type) {

      case `${resourceName}/LOADING`:
        return {
          ...state,
          loading: action.loading,
        };

      case `${resourceName}/CREATE`:
        return {
          ...state,
          create: action.create,
          edit: null,
        };

      case `${resourceName}/SET`: {
        const { filters, sortField, sortDirection } = state;

        const references = createReferences(action.docs);
        const list = createList(references, filters, sortField, sortDirection);
        const selected = resetIfUnknown(state.selected, state.references);
        const edit = resetIfUnknown(state.edit, state.references);
        const remove = resetIfUnknown(state.remove, state.references);

        return {
          ...state,
          references,
          list,
          selected,
          edit,
          remove,
        };
      }

      case `${resourceName}/SELECT`:
        return {
          ...state,
          selected: resetIfUnknown(action.id, state.references),
        };

      case `${resourceName}/EDIT`:
        return {
          ...state,
          edit: resetIfUnknown(action.id, state.references),
          create: false,
        };

      case `${resourceName}/REMOVE`:
        return {
          ...state,
          remove: resetIfUnknown(action.id, state.references),
        };

      case `${resourceName}/FILTER`: {
        const { field, filter, fuzzy } = action;
        const { references, sortField, sortDirection } = state;

        const filters = state.filters;

        if (filter) {
          if (fuzzy) {
            const regexp = new RegExp(`(${filter.replace(' ', '.*')})`, 'i');
            filters[field] = doc => doc.get(field).match(regexp);
          } else {
            filters[field] = doc => doc.get(field) === filter;
          }
        } else {
          delete filters[field];
        }

        const list = createList(references, filters, sortField, sortDirection);

        return {
          ...state,
          filters,
          list,
        };
      }

      case `${resourceName}/SORT`: {
        const { sortField, sortDirection } = action;
        const { references, filters } = state;

        const list = createList(references, filters, sortField, sortDirection);

        return {
          ...state,
          list,
          sortField,
          sortDirection,
        };
      }

      case `${resourceName}/CLOSE_EDITOR`:
        return {
          ...state,
          create: false,
          edit: null,
        };


      default:
        return {
          ...state,
        };
      }
  }
}
