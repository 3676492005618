import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import './Button.css';


export default ({ text, icon, to = '#', onClick, type, primary = false, active = false, className }) => {
  const handleClick = (e) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    }
  }

  const classes = {
    'button': true,
    'button--primary': primary,
    'button--active': active
  }

  const contentClasses = {
    'button__content': true,
    'button__content--icon-only': icon && !text,
    'button__content--text-only': !icon && text,
    'button__content--icon-text': icon && text,
  }

  const content = (
    <span className={classNames(contentClasses)}>
      {icon ? <i className={`button__icon fa fa-${icon}`} /> : null}
      {text ? <span className="button__text">{text}</span> : null}
    </span>
  );

  if (type) {
    return (
      <button type={type} onClick={handleClick} className={classNames(classes, className)}>
        {content}
      </button>
    )
  }

  return (
    <Link to={to} onClick={handleClick} className={classNames(classes, className)}>
      {content}
    </Link>
  );
};
