import React, { useReducer } from 'react';

import AppContext from './AppContext';
import rootReducer from '../rootReducer';
import rootState from '../rootState';


export default ({ children }) => {
  const [ state, dispatch ] = useReducer(rootReducer, rootState);

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};
