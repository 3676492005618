import React from 'react';
import classNames from 'classnames';

import './Button.css';


export default ({ padding, children }) => (
  <div className={classNames('button-group', { 'button-group--padding': padding })}>
    {children}
  </div>
);
