import React, { useContext, useEffect, useState } from 'react';

import AppContext from '../../../state/context/AppContext';
import TopicRelation from '../topic-relation/TopicRelation';
import EmptyTopicRelation from '../topic-relation/EmptyTopicRelation';
import { getFiltered, getDocument } from '../../../state/utils/stateUtils';

import './section-relation-editor.css';


export default ({ relations, onRelationsChange }) => {
  const { state: { types, topics } } = useContext(AppContext);
  const [ topicMapping, setTopicMapping ] = useState({});
  const [ topicList , setTopicList ] = useState([]);

  useEffect(() => {
    const editorTypes = getFiltered(types, type => type.get('showInSectionEditor')).map(type => type.id);
    const editorTopics = getFiltered(topics, topic => editorTypes.includes(topic.get('type')));

    const mapping = {};
    const list = [];

    editorTopics.forEach(topic => {
      const type = getDocument(types, topic.get('type'));
      const t = {
        id: topic.id,
        name: topic.get('name'),
        icon: type.get('icon'),
        sort: type.get('sort') || 0,
      };

      mapping[topic.id] = t;
      list.push(t);
    });

    list.sort((a, b) => {
      if (a.sort === b.sort) {
        const aValue = a.name.toUpperCase();
        const bValue = b.name.toUpperCase();

        if (aValue < bValue) {
          return -1;
        } else if (aValue > bValue) {
          return 1;
        } else {
          return 0;
        }
      }

      return b.sort - a.sort
    });

    setTopicMapping(mapping);
    setTopicList(list);
  }, [types, topics])


  const handleClick = (operation, id) => {
    console.log(operation, id)
    switch (operation) {
      case 'ADD':
        onRelationsChange([ ...relations, id ]);
        break;
      case 'REMOVE':
        onRelationsChange(relations.filter(topicId => topicId !== id))
        break;
    }
  }

  const selectedTopics = relations
    .map(relation => topicMapping[relation])
    .filter(topic => !!topic);

  const availableTopics = topicList
    .filter(topic => !relations.includes(topic.id));


  return (
    <div className="relation-list-editor">
      <label>Verwandte Themen</label>
      <div className="relation-list-editor__selected-topics">
        {selectedTopics
          .map(({ id, icon, name }) => (
            <TopicRelation key={id} icon={icon} name={name} actionIcon="close" selected onClick={handleClick.bind(null, 'REMOVE', id)} />
          ))}
        {(!selectedTopics || selectedTopics.length < 1) &&
          <EmptyTopicRelation text="Nichts ausgewählt" />
        }
      </div>
      <label>Mögliche Themen</label>
      <div className="relation-list-editor__not-selected-topics">
        {availableTopics.map(({ id, icon, name }) => (
          <TopicRelation key={id} icon={icon} name={name} actionIcon="plus" onClick={handleClick.bind(null, 'ADD', id)} />
        ))}
        {(!availableTopics || availableTopics.length < 1) &&
          <EmptyTopicRelation text="Nichts verfügbar" />
        }
      </div>
    </div>
  );
};
