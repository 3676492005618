import React, { useContext } from 'react';
import classNames from 'classnames';

import AppContext from '../../../state/context/AppContext';
import Action from '../../basic/action/Action';
import ActionGroup from '../../basic/action/ActionGroup';
import TopicBadge from '../../basic/topic-badge/TopicBadge';
import TopicBadgeGroup from '../../basic/topic-badge/TopicBadgeGroup';
import { getDocument } from '../../../state/utils/stateUtils';

import './section.css';


export default ({ section }) => {
  const { state: { topics, sections }, dispatch } = useContext(AppContext);
  const { remove } = sections;
  const relations = section.get('relations') || [];

  const handleEditClick = () => {
    dispatch({ type: 'sections/EDIT', id: section.id });
  }

  const handleRemoveClick = () => {
    dispatch({ type: 'sections/REMOVE', id: section.id });
  }

  const handleCancelClick = () => {
    dispatch({ type: 'sections/REMOVE', id: null });
  }

  const handleRemove = () => {
    section.ref.update({
      visible: false
    });
  }

  return (
    <>
      <div className={classNames('section', { 'section--remove': remove === section.id })}>
        {relations && (
          <div className="section__relations">
            <TopicBadgeGroup>
              {relations
                .map(topicId => getDocument(topics, topicId))
                .filter(topic => !!topic)
                .map(topic => <TopicBadge key={topic.id} topic={topic} />)
              }
            </TopicBadgeGroup>
          </div>
        )}
        <div className="section__inner">
          <div className="section__text">
            <p>{section.get('text')}</p>
          </div>
          <div className="section__actions">
            { remove === section.id &&
              <ActionGroup>
                <Action onClick={handleCancelClick} theme="cancel">Abbrechen</Action>
                <Action onClick={handleRemove} theme="delete">Löschen</Action>
              </ActionGroup>
            }
            { remove !== section.id &&
              <ActionGroup>
                <Action onClick={handleEditClick}>Bearbeiten</Action>
                <Action onClick={handleRemoveClick}>Löschen</Action>
              </ActionGroup>
            }
          </div>
        </div>
      </div>
    </>
  );
};
