
export default (defaultSortField, defaultSortDirection) => ({
  loading: false,
  references: {},
  list: [],
  selected: null,
  create: false,
  edit: null,
  remove: null,
  filters: {},
  sortField: defaultSortField,
  sortDirection: defaultSortDirection,
});
