import React, { useContext } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import AppContext from '../../../state/context/AppContext';
import Action from '../../basic/action/Action';
import ActionGroup from '../../basic/action/ActionGroup';
import TypeBadge from '../../basic/type-badge/TypeBadge';

import './topic.css';


export default ({ topic }) => {
  const { state: { types: { references }, topics: { selected, remove } }, dispatch } = useContext(AppContext);

  const handleSelect = () => {
    dispatch({ type: 'topics/SELECT', id: topic.id === selected ? null : topic.id });
  }

  const handleEditClick = () => {
    dispatch({ type: 'topics/EDIT', id: topic.id });
  }

  const handleRemoveClick = () => {
    dispatch({ type: 'topics/REMOVE', id: topic.id });
  }

  const handleCancelClick = () => {
    dispatch({ type: 'topics/REMOVE', id: null });
  }

  const handleRemove = () => {
    topic.ref.update({
      visible: false
    });
  }

  const type = references[topic.get('type')];

  return (
    <div className={classNames('topic', { 'topic--selected': selected === topic.id, 'topic--remove': remove === topic.id })}>
      <div className="topic__inner">
        <NavLink to="#" onClick={handleSelect} className="topic__info">
          <div className="topic__name">{topic.get('name')}</div>
          { type && <TypeBadge name={type.get('name')} icon={type.get('icon')} /> }
        </NavLink>
        <div className="topic__actions">
          { remove === topic &&
            <ActionGroup>
              <Action onClick={handleCancelClick} theme="cancel">Abbrechen</Action>
              <Action onClick={handleRemove} theme="delete">Löschen</Action>
            </ActionGroup>
          }
          { remove !== topic &&
            <ActionGroup>
              <Action onClick={handleEditClick}>Bearbeiten</Action>
              <Action onClick={handleRemoveClick}>Löschen</Action>
            </ActionGroup>
          }
        </div>
      </div>
    </div>
  );
};
