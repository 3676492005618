import React, { useState, useEffect, useContext } from 'react';

import AppContext from '../../../state/context/AppContext';
import EditorColumnHeader from '../../basic/column-header/editor/EditorColumnHeader';
import Text from '../../basic/input/Text';
import Number from '../../basic/input/Number';
import Checkbox from '../../basic/input/Checkbox';



export default ({ type }) => {
  const { state: { projects }, dispatch } = useContext(AppContext);

  const [ name, setName ] = useState('');
  const [ icon, setIcon ] = useState('');
  const [ sort, setSort ] = useState(0);
  const [ showInSectionEditor, setShowInSectionEditor ] = useState(false);

  useEffect(() => {
    const name = type ? type.get('name') : '';
    const icon = type ? type.get('icon') : '';
    const sort = type ? type.get('sort') : 0;
    const showInSectionEditor = type ? type.get('showInSectionEditor') : false;

    setName(name ? name : '');
    setIcon(icon ? icon : '');
    setSort(sort ? sort : 0);
    setShowInSectionEditor(showInSectionEditor ? showInSectionEditor : false);
  }, [ type ]);

  const reset = () => {
    setName('');
    setIcon('');
    setSort(0);
    setShowInSectionEditor(false);

    dispatch({ type: 'types/CLOSE_EDITOR' });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (type) {
      type.ref.update({ name, icon, showInSectionEditor, sort });
    } else {
      projects.references[projects.selected].ref.collection('types').add({ name, icon, showInSectionEditor, sort, visible: true, topicCount: 0 });
    }

    reset();
  }

  return (
    <div className="type-editor">
      <form onSubmit={handleSubmit}>
        <EditorColumnHeader onCancel={reset} />
        <Text name="name" label="Name" value={name} onChange={setName} autoComplete="off" required />
        <Text name="icon" label="Symbol" value={icon} onChange={setIcon} autoCorrect="off" autoCapitalize="off" required pattern="[a-z\-]+" />
        <Checkbox caption="Optionen" label="Standard für Einträge" value={showInSectionEditor} onChange={setShowInSectionEditor} />
        {showInSectionEditor && <Number name="sort" label="Sortierung" value={sort} onChange={setSort} autoCorrect="off" autoCapitalize="off" required />}
      </form>
    </div>
  );
};
