import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import AppContextProvider from '../../../state/context/AppContextProvider';
import UserProvider from '../../../providers/user/UserProvider';
import UserContext from '../../../providers/user/UserContext';
import LoginView from '../login/LoginView';
import LoadingView from '../loading/LoadingView';
import ProjectsProvider from '../../projects/provider/ProjectsProvider';
import ProjectView from '../project/ProjectView';
import ProjectSelectionView from '../project-selection/ProjectSelectionView';
import Logo from '../../basic/header/logo/Logo';
import ProjectTabs from '../../basic/header/projects/ProjectTabs';

import './app.css';


const defaultRoutes = (
    <ProjectsProvider>
      <ProjectTabs />
      <Switch>
        <Route exact path='/'component={ProjectSelectionView} />
        <Route path='/:id' component={ProjectView} />
      </Switch>
    </ProjectsProvider>
);

const loginRoutes = (
  <Switch>
    <Route path='/'component={LoginView} />
  </Switch>
);

const loadingRoutes = (
  <Switch>
    <Route path='/'component={LoadingView} />
  </Switch>
);

const Routes = () => {
  const { user } = useContext(UserContext);

  return user
      ? defaultRoutes
      : loginRoutes
};

export default () => (
  <BrowserRouter>
    <div className="app">
      <Logo />
      <UserProvider>
        <AppContextProvider>
          <Routes />
        </AppContextProvider>
      </UserProvider>
    </div>
  </BrowserRouter>
);
