import { useContext, useEffect } from 'react';

import AppContext from '../../../state/context/AppContext';
import { db } from '../../../config/firebase';


export default ({ children }) => {
  const { state: { projects, types, topics }, dispatch } = useContext(AppContext);

  useEffect(() => {
    if (projects.selected) {
      return db.collection(`projects/${projects.selected}/topics`).where('visible', '==', true).onSnapshot(
        snapshot => dispatch({ type: 'topics/SET', docs: snapshot.docs })
      );
    }
  }, [projects.selected]);

  useEffect(() => (
    dispatch({ type: 'topics/ON_SELECT', selected: topics.selected })
  ), [topics.selected])

  useEffect(() => (
    dispatch({ type: 'topics/FILTER', field: 'type', fuzzy: false, filter: types.selected })
  ), [types.selected]);

  return children;
};
