import createReducer from "../../state/utils/createReducer";

const baseReducer = createReducer('relations');

export default (state, action) => {
  switch (action.type) {

    case 'projects/ON_SELECT':
    case 'topics/ON_SELECT':
      return {
        ...state,
        references: {},
        list: [],
        selected: null,
        create: false,
        edit: null,
        remove: null,
        filters: {},
      };

    default:
      return baseReducer(state, action);
  }
};

