import { useContext, useEffect } from 'react';

import AppContext from '../../../state/context/AppContext';
import { db } from '../../../config/firebase';


export default ({ children }) => {
  const { state: { projects, types }, dispatch } = useContext(AppContext);

  useEffect(() => {
    if (projects.selected) {
      return db.collection(`projects/${projects.selected}/types`).where('visible', '==', true).onSnapshot(
        snapshot => dispatch({ type: 'types/SET', docs: snapshot.docs })
      );
    }
  }, [projects.selected]);

  useEffect(() => (
    dispatch({ type: 'types/ON_SELECT', selected: types.selected })
  ), [types.selected])

  return children;
};
