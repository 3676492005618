import React, { useContext } from 'react';

import AppContext from '../../../state/context/AppContext';
import ListColumnHeader from '../../basic/column-header/list/ListColumnHeader';
import Section from '../../sections/item/Section';
import Separator from '../../basic/separator/Separator';
import { getSelected, getList } from '../../../state/utils/stateUtils';
import Relation from '../../relations/item/Relation';

import './topic-details.css';


export default () => {
  const { state: { topics, sections, relations }, dispatch } = useContext(AppContext);

  const currentTopic = getSelected(topics);
  const sectionList = getList(sections);
  const relationList = getList(relations);

  const sectionRelations = {};

  relationList.filter(relation => !!relation.get('section')).forEach(relation => {
    const section = relation.get('section');

    sectionRelations[section] = sectionRelations[section] || [];
    sectionRelations[section].push(relation);
  });

  const showSeparator = sectionList && sectionList.length > 0 && relationList && relationList.length > 0;

  const handleCreateClick = () => (
    dispatch({ type: 'sections/CREATE', create: true })
  );

  const handleLinkClick = () => (
    dispatch({ type: 'relations/CREATE', create: true })
  );

  return (
    <>
      <ListColumnHeader onCreateClick={handleCreateClick} onLinkClick={handleLinkClick} showButtons={!!currentTopic} noMargin />
      {(sectionList.length > 0 || relationList.length > 0) && (
        <div className="topic-details">
          {sectionList.map(section => (
            <Section key={section.id} section={section} />
          ))}
          {showSeparator && <Separator slim />}
          {relationList.filter(relation => !relation.get('section')).map(relation => (
            <Relation key={relation.id} relation={relation} />
          ))}
        </div>
      )}
    </>
  );
};
