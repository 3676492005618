import React from 'react';
import classNames from 'classnames';

import './topic-relation.css';


export default ({ text }) => {
  return (
    <div className="topic-relation--empty">
      {text}
    </div>
  );
};
