import React, { useState, useEffect, useContext } from 'react';
import firebase from 'firebase/app';

import AppContext from '../../../state/context/AppContext';
import EditorColumnHeader from '../../basic/column-header/editor/EditorColumnHeader';
import TextArea from '../../basic/input/TextArea';
import { getSelected, getAll, getDocument } from '../../../state/utils/stateUtils';
import SectionRelationEditor from '../../basic/section-relation-editor/SectionRelationEditor';

import './section-editor.css';


const getSectionTypes = types => (
  getAll(types)
    .filter(type => type.get('showInSectionEditor'))
);

export default ({ section }) => {
  const { state: { types, topics }, dispatch } = useContext(AppContext);
  const selectedTopic = getSelected(topics);
  const sectionTypes = getSectionTypes(types);

  const [ text, setText ] = useState('');
  const [ relations, setRelations ] = useState([]);


  useEffect(() => {
    const text = section ? section.get('text') : '';
    const relations = section ? section.get('relations') : [];

    setText(text || '');
    setRelations(relations || [] );
  }, [ section ]);

  const reset = () => {
    setText('');
    setRelations([]);

    dispatch({ type: 'sections/CLOSE_EDITOR' });
  }

  const handleLinkChange = id => {
    if (id) {
      setRelations([
        ...relations,
        id
      ]);
    } else {
      setRelations(relations.filter(topicId => topicId !== id))
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (section) {
      section.ref.update({ text, relations });
    } else {
      selectedTopic.ref.collection('sections').add({
        text,
        relations,
        visible: true,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date())
      });
    }

    reset();
  }

  return (
    <div className="section-editor">
      <form onSubmit={handleSubmit}>
        <EditorColumnHeader onCancel={reset} left={true} />
        <div className="section-editor__container">
          <TextArea name="text" label="Text" value={text} onChange={setText} rows={4} required />
          <SectionRelationEditor relations={relations} onRelationsChange={setRelations} />
        </div>
      </form>
    </div>
  );
};
