import React, { useState, useEffect, useContext } from 'react';
import firebase from 'firebase/app';

import AppContext from '../../../state/context/AppContext';
import EditorColumnHeader from '../../basic/column-header/editor/EditorColumnHeader';
import Text from '../../basic/input/Text';
import Radio from '../../basic/input/Radio';
import { createList } from '../../../state/utils/reducerUtils';
import { getSelected, getDocument } from '../../../state/utils/stateUtils';


export default ({ topic }) => {
  const { state: { projects, types }, dispatch } = useContext(AppContext);
  const { selected, references } = types;

  const selectedProject = getSelected(projects);

  const [ name, setName ] = useState('');
  const [ type, setType ] = useState('');

  useEffect(() => {
    setName(topic ? topic.get('name') : '');
    setType(topic ? topic.get('type') : (selected ? selected : ''));
  }, [ topic, selected ]);

  const reset = () => {
    setName('');
    setType('');

    dispatch({ type: 'topics/CLOSE_EDITOR' });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (topic) {
      topic.ref.update({ name, type: type });
    } else {
      selectedProject.ref.collection('topics').add({
        name,
        type: type,
        visible: true,
        createdAt: firebase.firestore.Timestamp.fromDate(new Date())
      });
    }

    reset();
  }

  const typeIds = createList(types.references, {}, 'name', 1);
  const options = typeIds.map(id => ({ value: id, label: types.references[id].get('name') }));

  return (
    <div className="topic-editor">
      <form onSubmit={handleSubmit}>
        <EditorColumnHeader onCancel={reset} />
        <Text name="name" label="Name" value={name} onChange={setName} autoComplete="off" required />
        <Radio label="Kategorie" options={options} value={type} onChange={setType} />
      </form>
    </div>
  );
};
