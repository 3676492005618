import React from 'react';
import classNames from 'classnames';

import './topic-relation.css';


export default ({ icon, name, actionIcon, selected = false, onClick }) => {

  const handleClick = e => {
    e.preventDefault();

    if (onClick) {
      onClick();
    }
  }

  return (
    <div className={classNames('topic-relation', { 'topic-relation--selected': selected })}>
      <span className="topic-relation__icon">
        <i className={`fa fa-${icon}`} />
      </span>
      <span className="topic-relation__name">
        {name}
      </span>
      {onClick &&
        <a href="#" className="topic-relation__action" onClick={handleClick}>
          <i className={`fa fa-${actionIcon}`} />
        </a>
      }
    </div>
  );
};
