import React, { useContext } from 'react';

import AppContext from '../../../state/context/AppContext';
import { getDocument } from '../../../state/utils/stateUtils';

import './topic-badge.css';


export default ({ topic }) => {
  const { state: { types }, dispatch } = useContext(AppContext);
  const type = getDocument(types, topic.get('type'));

  const handleClick = e => {
    e.preventDefault();
    dispatch({ type: 'topics/SELECT', id: topic.id });
  };

  return (
    <span className="topic-badge" onClick={handleClick}>
      <span className="topic-badge__icon"><i className={`fa fa-${type.get('icon')}`} /></span>
      <span className="topic-badge__name">{topic.get('name')}</span>
    </span>
  );
};
