import settingsReducer from './settingsReducer';
import projectsReducer from '../components/projects/projectsReducer';
import typesReducer from '../components/types/typesReducer';
import topicsReducer from '../components/topics/topicsReducer';
import sectionsReducer from '../components/sections/sectionsReducer';
import relationsReducer from '../components/relations/relationsReducer';

export default (state, action) => {
  const newState = {
    settings: settingsReducer(state.settings, action),
    projects: projectsReducer(state.projects, action),
    types: typesReducer(state.types, action),
    topics: topicsReducer(state.topics, action),
    sections: sectionsReducer(state.sections, action),
    relations: relationsReducer(state.relations, action),
  };

  //console.log('STATE', state, 'ACTION', action, 'STATE', newState);
  window.state = newState;

  return newState;
};
