import React, { useContext } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import AppContext from '../../../state/context/AppContext';
import Action from '../../basic/action/Action';
import ActionGroup from '../../basic/action/ActionGroup';
import { getSelected, getDocument, getRemove } from '../../../state/utils/stateUtils';

import './relation.css';


export default ({ relation }) => {
  const { state: { relations, topics, types, projects }, dispatch } = useContext(AppContext);

  const selectedRelation = getSelected(relations);
  const selectedTopic = getSelected(topics);
  const removeRelation = getRemove(relations);

  const topic = getDocument(topics, relation.get('topic'));
  const type = topic ? getDocument(types, topic.get('type')) : null;


  const handleSelect = () => {
    dispatch({ type: 'relations/SELECT', id: relation === selectedRelation ? null : relation.id });
  }

  const handleViewClick = () => {
    dispatch({ type: 'topics/SELECT', id: relation.get('topic') })
  }

  const handleEditClick = () => {
    dispatch({ type: 'relations/EDIT', id: relation.id });
  }

  const handleCancelClick = () => {
    dispatch({ type: 'relations/REMOVE', id: null });
  }

  const handleRemoveClick = () => {
    dispatch({ type: 'relations/REMOVE', id: relation.id });
  }

  const handleRemove = () => {
    relation.ref.delete();
    topic.ref.collection('relations').doc(`T:${selectedTopic.id}`).delete();
  }

  const classes = {
    'relation--selected': selectedRelation === relation,
    'relation--remove': removeRelation === relation,
  }

  const text = relation.get('text');

  return (
    <div className={classNames('relation', classes)}>
      <div className="relation__inner">
        <NavLink to="#" onClick={handleSelect} className="relation__info">
          <div className="relation__icon"><i className={`fa fa-${type ? type.get('icon') : 'question-circle'}`} /></div>
          {text && <div className="relation__text">{text}</div>}
          <div className="relation__topic">{topic ? topic.get('name') : '?'}</div>
        </NavLink>
        <div className="relation__actions">
          { removeRelation === relation &&
            <ActionGroup>
              <Action onClick={handleCancelClick} theme="cancel">Abbrechen</Action>
              <Action onClick={handleRemove} theme="delete">Löschen</Action>
            </ActionGroup>
          }
          { removeRelation !== relation &&
            <ActionGroup>
              <Action onClick={handleViewClick}>Ansehen</Action>
              <Action onClick={handleEditClick}>Bearbeiten</Action>
              <Action onClick={handleRemoveClick}>Löschen</Action>
            </ActionGroup>
          }
        </div>
      </div>
    </div>
  );
};
