import React from 'react';

import './type-badge.css';


export default ({ name, icon }) => (
  <span className="type-badge">
    {name} <i className={`fa fa-${icon}`} />
  </span>
);
