import React, { useState, useEffect, useContext } from 'react';
import firebase from 'firebase/app';

import AppContext from '../../../state/context/AppContext';
import EditorColumnHeader from '../../basic/column-header/editor/EditorColumnHeader';
import Text from '../../basic/input/Text';
import { getSelected, getDocument } from '../../../state/utils/stateUtils';

import './relation-editor.css';
import TopicRelation from '../../basic/topic-relation/TopicRelation';
import TopicRelationEditor from '../../basic/topic-relation-editor/TopicRelationEditor';


export default ({ relation }) => {
  const { state: { types, topics }, dispatch } = useContext(AppContext);
  const selectedTopic = getSelected(topics);

  const [ loading, setLoading ] = useState(true);
  const [ relatedTopic, setRelatedTopic ] = useState(null);
  const [ relatedTopicType, setRelatedTopicType ] = useState(null);
  const [ reverseRelation, setReverseRelation ] = useState(null);
  const [ text, setText ] = useState('');
  const [ reverseText, setReverseText ] = useState('');

  useEffect(() => {
    setLoading(!!relation);

    const relatedTopic = relation ? getDocument(topics, relation.get('topic')) : null;
    const text = relation ? relation.get('text') : '';

    setRelatedTopic(relatedTopic || null);
    setText(text || '');
  }, [relation]);

  useEffect(() => {
    if (relatedTopic) {
      setRelatedTopicType(getDocument(types, relatedTopic.get('type')));
      relatedTopic.ref.collection('relations').doc(`T:${selectedTopic.id}`).get().then(doc => {
        if (doc.exists) {
          setReverseRelation(doc);
          setReverseText(doc.get('text'));
        }

        setLoading(false);
      });
    }
  }, [relatedTopic]);

  const reset = () => {
    setRelatedTopic(null);
    setRelatedTopicType(null);
    setReverseRelation(null);
    setText('');
    setReverseText('');

    dispatch({ type: 'relations/CLOSE_EDITOR' });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (selectedTopic && relatedTopic) {
      selectedTopic.ref.collection('relations').doc(`T:${relatedTopic.id}`).set({
        text,
        topic: relatedTopic.id
      });
      relatedTopic.ref.collection('relations').doc(`T:${selectedTopic.id}`).set({
        text: reverseText,
        topic: selectedTopic.id
      });

      reset();
    }
  }

  return (
    <div className="relation-editor">
      <form onSubmit={handleSubmit}>
        <EditorColumnHeader onCancel={reset} left={true} showSaveButton={!loading} />
        {!loading &&
          <div className="relation-editor__container">
            <Text name="text" label={`… ${relatedTopic ? relatedTopic.get('name') : '?'}`} value={text} onChange={setText} autoComplete="off" />
            <Text name="reverseText" label={`… ${selectedTopic.get('name')}`} value={reverseText} onChange={setReverseText} autoComplete="off" />
            {relation && relatedTopic && relatedTopicType && (
              <div className="relation-editor__topic">
                <label>Thema</label>
                <TopicRelation icon={relatedTopicType.get('icon')} name={relatedTopic.get('name')} selected />
              </div>
            )}
            {!relation &&
              <TopicRelationEditor relatedTopic={relatedTopic} onRelatedTopicChange={setRelatedTopic} />
            }
          </div>
        }
      </form>
    </div>
  );
};
