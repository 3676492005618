import { useContext, useEffect } from 'react';

import AppContext from '../../../state/context/AppContext';
import { db } from '../../../config/firebase';


export default ({ children }) => {
  const { state: { projects: { selected } }, dispatch } = useContext(AppContext);

  useEffect(() => (
    db.collection('projects').where('visible', '==', true).onSnapshot(
      snapshot => dispatch({ type: 'projects/SET', docs: snapshot.docs })
    )
  ), []);

  useEffect(() => (
    dispatch({ type: 'projects/ON_SELECT', selected })
  ), [selected])

  return children;
};
