import { useContext, useEffect } from 'react';

import AppContext from '../../../state/context/AppContext';
import { getSelected } from '../../../state/utils/stateUtils';


export default ({ children }) => {
  const { state: { topics }, dispatch } = useContext(AppContext);
  const selectedTopic = getSelected(topics);

  useEffect(() => {
    if (selectedTopic) {
      return selectedTopic.ref.collection('sections').where('visible', '==', true).onSnapshot(
        snapshot => dispatch({ type: 'sections/SET', docs: snapshot.docs })
      );
    }
  }, [selectedTopic]);

  return children;
};
