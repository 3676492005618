import React from 'react';
import classNames from 'classnames';

import './separator.css';


export default ({ text, slim = false }) => (
  <div className={classNames('separator', { 'separator--slim': slim })}>
    { text && <span className="separator__text">{text}</span> }
  </div>
);
