import React, { useContext } from 'react';

import AppContext from '../../../state/context/AppContext';
import TopicDetails from '../details/TopicDetails';
import SectionEditor from '../../sections/editor/SectionEditor';
import RelationEditor from '../../relations/editor/RelationEditor';
import { getEdit } from '../../../state/utils/stateUtils';

import './topic-column.css';


export default () => {
  const { state: { sections, relations } } = useContext(AppContext);
  const sectionEdit = getEdit(sections);
  const sectionCreate = sections.create;
  const relationEdit = getEdit(relations);
  const relationCreate = relations.create;

  return (
    <div className="topic-column">
      { sectionEdit && <SectionEditor section={sectionEdit} /> }
      { sectionCreate && <SectionEditor section={null} /> }
      { relationEdit && <RelationEditor relation={relationEdit} /> }
      { relationCreate && <RelationEditor relation={null} /> }
      { !sectionEdit && !sectionCreate &&
        !relationEdit && !relationCreate && <TopicDetails /> }
    </div>
  );
};
