import React, { useContext } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import AppContext from '../../../state/context/AppContext';
import Action from '../../basic/action/Action';
import ActionGroup from '../../basic/action/ActionGroup';
import Count from '../../basic/count/Count';

import './type.css';


export default ({ type }) => {
  const { state: { types: { selected, remove } }, dispatch } = useContext(AppContext);

  const handleSelect = () => {
    dispatch({ type: 'types/SELECT', id: type.id === selected ? null : type.id });
  }

  const handleEditClick = () => {
    dispatch({ type: 'types/EDIT', id: type.id });
  }

  const handleCancelClick = () => {
    dispatch({ type: 'types/REMOVE', id: null });
  }

  const handleRemoveClick = () => {
    dispatch({ type: 'types/REMOVE', id: type.id });
  }

  const handleRemove = () => {
    type.ref.update({
      visible: false
    });
  }

  return (
    <div className={classNames('type', { 'type--selected': selected === type.id, 'type--remove': remove === type.id })}>
      <div className="type__inner">
        <NavLink to="#" onClick={handleSelect} className="type__info">
          <div className="type__icon"><i className={`fa fa-${type.get('icon')}`} /></div>
          <div className="type__name">{type.get('name')}</div>
          {//<div className="type__topic-count"><Count count={0} /></div>
          }
        </NavLink>
        <div className="type__actions">
          { remove === type.id &&
            <ActionGroup>
              <Action onClick={handleCancelClick} theme="cancel">Abbrechen</Action>
              <Action onClick={handleRemove} theme="delete">Löschen</Action>
            </ActionGroup>
          }
          { remove !== type.id &&
            <ActionGroup>
              <Action onClick={handleEditClick}>Bearbeiten</Action>
              <Action onClick={handleRemoveClick}>Löschen</Action>
            </ActionGroup>
          }
        </div>
      </div>
    </div>
  );
};
