import React, { useContext } from 'react';
import moment from 'moment';
import 'moment/locale/de'

import AppContext from '../../../state/context/AppContext';
import Button from '../../basic/button/Button';
import ListColumnHeader from '../../basic/column-header/list/ListColumnHeader';
import Topic from '../item/Topic';
import Separator from '../../basic/separator/Separator';

import './topics-list.css';


moment.locale('de');

const getCurrentGroup = (topic, sortField) => {
  switch (sortField) {
    case 'name':
      const name = topic.get('name');
      return name && name.length > 0 ? name.charAt(0).toUpperCase() : '';
    case 'createdAt':
      const createdAt = topic.get('createdAt');
      return moment.unix(createdAt.seconds).fromNow();
  }
}


export default () => {
  const { state: { topics }, dispatch } = useContext(AppContext);
  const { references, list, sortField, sortDirection } = topics;


  const handleSort = (field) => {
    dispatch({ type: 'topics/SORT', sortField: field, sortDirection: field === sortField && sortDirection > 0 ? -1 : 1 });
  };

  const handleCreatedAtSort = () => {
    dispatch({ type: 'topics/SORT', sortField: 'createdAt', sortDirection: -1 });
  };

  const sortIcon = (field, icon) => (
    sortField === field && sortDirection < 0 ? `${icon}-desc` : `${icon}-asc`
  );

  const handleCreateClick = () => (
    dispatch({ type: 'topics/CREATE', create: true })
  );

  const handleFilterChange = value => (
    dispatch({ type: 'topics/FILTER', field: 'name', fuzzy: true, filter: value })
  );

  let lastGroup = '';


  return (
    <>
      <ListColumnHeader onCreateClick={handleCreateClick} onFilterChange={handleFilterChange} showTypesButton>
        <Button onClick={handleSort.bind(null, 'name')} icon={sortIcon('name', 'sort-alpha')} active={sortField === 'name'} />
        <Button onClick={handleCreatedAtSort} icon="calendar" active={sortField === 'createdAt'} />
      </ListColumnHeader>
      <div className="topics-list">
        {list.map(id => {
          const topic = references[id];
          const name = topic.get('name');
          const group = getCurrentGroup(topic, sortField);

          if (group !== lastGroup) {
            lastGroup = group;
            return (
              <>
                <Separator key={group} text={group} />
                <Topic key={id} topic={topic} />
              </>
            );
          } else {
            return <Topic key={id} topic={topic} />
          }
        })}
      </div>
    </>
  );
};
