import React from 'react';
import classNames from 'classnames';

import './action.css';


export default ({ onClick, theme, children }) => (
  <button className={classNames('action', `action--${theme}`)} onClick={onClick}>
    {children}
  </button>
);
