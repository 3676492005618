import createInitialState from "./utils/createInitialState";


export default {
  settings: {
    showTypesColumn: false,
  },
  projects: createInitialState('name', 1),
  types: createInitialState('name', 1),
  topics: createInitialState('name', 1),
  sections: createInitialState('createdAt', 1),
  relations: createInitialState('text', 1),
}
