import React, { useState, useEffect } from 'react';

import { auth } from '../../config/firebase';
import UserContext from './UserContext';


export default ({ children }) => {
  const [ user, setUser ] = useState(null);

  useEffect(() => {
    auth.onAuthStateChanged(user => {
      setUser(user);
    });
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}
