import React, { useState, useRef, useContext } from 'react';
import classNames from 'classnames';

import AppContext from '../../../../state/context/AppContext';
import Button from '../../button/Button'
import ButtonGroup from '../../button/ButtonGroup';

import './list.css';


export default ({ onCreateClick, onLinkClick, filter, onFilterChange, showButtons = true, showTypesButton = false, noMargin = false, children }) => {
  const { state: { settings: { showTypesColumn } }, dispatch } = useContext(AppContext);
  const [ filterToggle, setFilterToggle ] = useState(false);
  const filterInput = useRef(null);

  const handleFilterClick = () => {
    if (!filterToggle) {
      filterInput.current.focus();
      filterInput.current.select();
    }

    setFilterToggle(!filterToggle);
  }

  const handleFilterChange = (e) => {
    const value = e.target.value;

    if (onFilterChange) {
      onFilterChange(value);
    }
  }

  const handleFolderClick = () => (
    dispatch({ type: 'settings/TOGGLE_TYPES_COLUMN' })
  )


  return (
    <div className={classNames('list-header', { 'list-header--filter': filterToggle, 'list-header--no-margin': noMargin })}>
        <div className="list-header__inner">
          <div className="list-header__buttons">
            { showButtons &&
              <>
                <ButtonGroup>
                  { !!onCreateClick ? <Button icon="plus" onClick={onCreateClick} /> : null }
                  { !!onLinkClick ? <Button icon="link" onClick={onLinkClick} /> : null }
                </ButtonGroup>
                { showTypesButton ? <Button icon="tags" onClick={handleFolderClick} className="small-device" active={showTypesColumn} /> : null }
              </>
            }
            <div className="list-header__buttons__filler" />
            { showButtons &&
              <ButtonGroup>
                { children }
              </ButtonGroup>
            }
            { showButtons && !!onFilterChange ? <Button icon="search" onClick={handleFilterClick} /> : null }
          </div>
          { onFilterChange &&
            <div className="list-header__filter">
              <input
                type="text" name="filter" placeholder="Filter…"
                className="list-header__filter__input"
                value={filter} onChange={handleFilterChange} ref={filterInput} />
              <Button icon="close" onClick={handleFilterClick} />
            </div>
          }
        </div>
    </div>
  );
};
