import React, { useContext, useEffect } from 'react';


import AppContext from '../../../state/context/AppContext';
import TypesProvider from '../../types/provider/TypesProvider';
import TopicsProvider from '../../topics/provider/TopicsProvider';
import TypesColumn from '../../types/column/TypesColumn';
import TopicsColumn from '../../topics/column/TopicsColumn';
import TopicColumn from '../../topic/column/TopicColumn';
import SectionsProvider from '../../sections/provider/SectionsProvider';
import RelationsProvider from '../../relations/provider/RelationsProvider';


export default ({ match: { params: { id } } }) => {
  const { state, dispatch } = useContext(AppContext);
  const { projects: { references } } = state;

  useEffect(() => {
    dispatch({ type: 'projects/SELECT', id });
  }, [ id, references ]);

  return (
    <>
      <TypesProvider>
        <TopicsProvider>
          <TypesColumn />
          <TopicsColumn />
          <SectionsProvider>
            <RelationsProvider>
              <TopicColumn />
            </RelationsProvider>
          </SectionsProvider>
        </TopicsProvider>
      </TypesProvider>
    </>
  )
};
