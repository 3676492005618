import React from 'react';
import classNames from 'classnames';

import './input.css';


export default ({ options = [], label, value, onChange, deselectable }) => (
  <div className={classNames('input', 'input--radio', { 'input--radio--deselectable': deselectable })}>
    <label>{label}</label>

    <div className="input--radio__container">
      {options.map(option => {
        const isSelected = value === option.value;
        const icon = !isSelected ? 'fa-circle' : (
          deselectable ? 'fa-check-circle' : 'fa-dot-circle-o'
        );

        const handleClick = (e) => {
          e.preventDefault();
          onChange(deselectable && isSelected ? null : option.value);
        }

        return (
          <a key={option.value} href="#"
            className={classNames('input--radio__option', { 'input--radio__option--selected': value === option.value })}
            onClick={handleClick}>
            <i className={classNames('fa', icon)} />{option.label}
          </a>
        );
      })}
    </div>
  </div>
);
