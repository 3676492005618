import React from 'react';

import './logo.css';


export default () => (
  <div className="logo">
    kase
  </div>
);
