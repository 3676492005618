import React from 'react';

import './action.css';


export default ({ children, separators = true }) => (
  <div className="action__group">
    {
      children.map((child, index) => ([
        child,
        separators && index !== children.length - 1 && <span key={index} className="action__separator" />
      ]))
    }
  </div>
);
