
export const createReferences = (resources) => {
  const references = {};
  resources && resources.forEach(resource => {
    references[resource.id] = resource;
  });

  return references;
}

export const createList = (references, filters, sortField, sortDirection) => {
  let list = [];
  for (let id in references) {
    list.push(references[id]);
  }

  list = list.filter(doc => {
    let keep = true;

    for (let field in filters) {
      keep = keep && filters[field](doc);
    }

    return keep;
  });

  list.sort((a, b) => {
    let aValue = a.get(sortField);
    let bValue = b.get(sortField);

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      aValue = aValue.toUpperCase();
      bValue = bValue.toUpperCase();

      if (aValue < bValue) {
        return -1 * sortDirection;
      } else if (aValue > bValue) {
        return 1 * sortDirection;
      } else {
        return 0;
      }
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      return (aValue - bValue) * sortDirection;
    } else if (aValue.seconds && bValue.seconds) {
      return (aValue.seconds - bValue.seconds) * sortDirection;
    } else {
      return 0;
    }
  });

  return list.map(item => item.id);
};

export const resetIfUnknown = (id, references) => (
  id && references && references[id] ? id : null
);
